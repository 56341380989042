import { render, staticRenderFns } from "./priorities.vue?vue&type=template&id=206a85ae&scoped=true&"
import script from "./priorities.vue?vue&type=script&lang=js&"
export * from "./priorities.vue?vue&type=script&lang=js&"
import style0 from "./priorities.vue?vue&type=style&index=0&id=206a85ae&lang=scss&scoped=true&"
import style1 from "./priorities.vue?vue&type=style&index=1&lang=scss&"
import style2 from "./priorities.vue?vue&type=style&index=2&id=206a85ae&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "206a85ae",
  null
  
)

export default component.exports