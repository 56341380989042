<template>
  <div>
    <b-modal
      id="modal-login"
      no-close-on-backdrop
      size="sm"
      centered
      ok-only
      hide-footer="true"
      title="Priority"
    >
      <b-form>
        <b-row class="mb-1">
          <b-col>
            <b-form-group
              ref="simage"
              label="Icon (100 x 100)"
              invalid-feedback="Icon is required."
            >
              <b-media no-body>
                <div v-if="singleLoading == true">
                  <b-spinner
                    variant="primary"
                    type="grow"
                    class="m-5"
                    label="Spinning"
                  ></b-spinner>
                </div>

                <b-media-aside>
                  <b-link>
                    <b-img
                      ref="previewEl"
                      rounded
                      src="https://cdn.cloudious.net/file-1678189226453-406060527.png"
                      @click="$refs.singleImg.click()"
                      v-if="singleLoading == false"
                      style="height: 120px; width: 120px; object-fit: cover"
                    />
                  </b-link>

                  <b-link v-if="singleLoading == 'loaded'">
                    <div>
                      <i
                        @click="deleteSingle()"
                        style="margin-top: 70px; margin-left: 70px"
                        class="fa fa-trash fa-md text-danger position-absolute"
                      ></i>
                    </div>
                    <b-img
                      rounded
                      :src="myObj.icon"
                      style="height: 120px; width: 120px; object-fit: contain;background-color: lightgray;"
                    />
                    <br />
                  </b-link>

                  <input
                    type="file"
                    id="singleImg"
                    hidden
                    ref="singleImg"
                    @change="selectSingle()"
                    accept="image/*"
                  />
                </b-media-aside>
              </b-media>
            </b-form-group>
          </b-col>
        </b-row>

        <b-form-group label="Title" invalid-feedback="Title is required.">
          <b-form-input
            ref="name"
            placeholder="Enter Title here."
            required
            @focusout="CheckName()"
            v-model="myObj.title"
          />
        </b-form-group>
        <b-form-group
          label="Page"
          invalid-feedback="Page is required."
          ref="page"
        >
          <v-select
            v-model="myObj.pageID"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="pageOptions"
            label="title"
            :reduce="(val) => val.id"
            :clearable="false"
            placeholder="select page"
            @input="checkPage()"
          />
        </b-form-group>
      </b-form>
      <br />
      <div style="">
        <b-button
          class="float-right ml-5"
          variant="primary"
          @click="AddFlavour()"
          :disabled="singleLoading == true || saving"
        >
          <b-spinner
            v-if="saving"
            variant="light"
            type="grow"
            small
            label="Spinning"
          ></b-spinner>
          <span v-else class="text-nowrap">Save</span>
        </b-button>
      </div>
    </b-modal>

    <b-card no-body class="mb-0">
      <div class="m-2">
        <b-row>
          <b-col class="mt-2" md="10" sm="8">
            <div class="d-flex align-items-center justify-content-start">
              <div style="padding-right: 15px">
                <b-button @click="OpenAddModal(0)" variant="primary">
                  <feather-icon icon="PlusIcon" class="mr-50" />
                  <span class="text-nowrap">Add Priority</span>
                </b-button>
              </div>
            </div>
          </b-col>
        </b-row>
      </div>

      <div>
        <b-table
          :items="items"
          :fields="fields"
          show-empty
          responsive
          :busy="dataLoading"
        >
          <template #table-busy>
            <div class="text-center my-5">
              <b-spinner
                class="align-middle"
                type="grow"
                variant="primary"
                style="width: 3.5rem; height: 3.5rem;"
              ></b-spinner>
            </div>
          </template>

          <template #cell(#)="data">
            {{ data.index + 1 }}
          </template>
          <template #cell(icon)="data">
            <b-img
              :src="data.value"
              style="height: 120px; width: 120px; object-fit: contain;background-color: lightgray"
              rounded
              alt="icon"
            />
          </template>

          <template #cell(actions)="data">
            <template>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="btn-icon"
                v-b-tooltip.hover.v-primary
                v-b-tooltip.placement.left
                title="Edit"
                @click="OpenAddModal(data.item)"
              >
                <feather-icon size="16" icon="EditIcon" />
              </b-button>

              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="outline-danger"
                class="ml-1 btn-icon"
                v-b-tooltip.hover.v-danger
                v-b-tooltip.placement.right
                title="Delete"
                size="16"
                @click="DeleteFlavour(data.item.id)"
              >
                <feather-icon size="16" icon="XIcon" />
              </b-button>
            </template>
          </template>
        </b-table>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BFormGroup,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BMediaAside,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BImg,
  VBTooltip,
  BSpinner,
  BFormCheckbox,
  BFormTextarea,
} from "bootstrap-vue";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import VueGallery from "vue-gallery";
import Ripple from "vue-ripple-directive";
import { icon } from "leaflet";

export default {
  components: {
    BFormTextarea,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BMediaAside,
    BSpinner,
    BImg,
    BMedia,
    BFormGroup,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
    BFormCheckbox,
    flatPickr,
  },

  directives: {
    "b-tooltip": VBTooltip,
    Ripple,
  },
  created() {
    let obj = this.$store.state.pageRights.find(
      (el) => el.route == this.$route.name
    );
    if (!obj) {
      this.$router.replace({ name: "misc-not-authorized" });
    } else {
      this.LoadData();
      this.LoadPages();
    }
  },
  data() {
    return {
      plus: "",
      fileProfile: "",
      logoloading: false,
      singleLoading: false,
      saving: false,
      fields: [
        "#",
        { label: "icon", key: "icon" },
        { label: "title", key: "title" },
        "actions",
      ],
      items: [],
      request: false,
      myObj: {
        id: 0,
        icon: "",
        title: "",
        pageID: 0,
      },
      searchQuery: "",
      dataLoading: false,
      pageOptions: [],
    };
  },
  methods: {
    CheckName() {
      var elem = this.$refs["name"];
      if (this.myObj.title.trim() == "") {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    CheckSImage() {
      var elem = this.$refs["simage"];
      if (this.myObj.icon == "") {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    checkPage() {
      var elem = this.$refs["page"];
      if (this.myObj.pageID == 0) {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    OpenAddModal(id) {
      if (id == 0) {
        this.myObj = {
          id: 0,
          icon: "",
          title: "",
          pageID: 0,
        };
        this.singleLoading = false;
        this.$bvModal.show("modal-login");
      } else {
        this.myObj = { ...id };
        this.singleLoading = "loaded";
        this.$bvModal.show("modal-login");
      }
    },
    DeleteFlavour(id) {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, Delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          var axios = require("axios");
          var config = {
            method: "delete",
            url: "https://api.worldappeal.org.uk/api/Priorities/" + id,
            headers: {
              Authorization: "bearer " + this.$store.state.userData.token,
            },
          };
          axios(config)
            .then((response) => {
              if (response.data.status === "success") {
                Swal.fire(
                  "Success!",
                  "Priority has been deleted.",
                  "success"
                ).then((res) => {
                  this.LoadData();
                });
              }
            })
            .catch(function(error) {
              console.log(error);
            });
        }
      });
    },
    selectSingle() {
      let obj = this.$refs.singleImg.files[0];

      var axios = require("axios");
      if (obj !== "") {
        this.singleLoading = true;
        let formData = new FormData();
        formData.append("file", obj);
        axios
          .post("https://upload.myskool.app", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((e) => {
            // console.log(e.data);
            this.myObj.icon = e.data.myresp[0].path;
            this.singleLoading = "loaded";
            this.CheckSImage();
          })
          .catch((err) => {
            console.log(err);
            this.singleLoading = false;
          });
      }
    },
    deleteSingle() {
      this.myObj.icon = "";
      this.singleLoading = false;
      this.$refs.singleImg.value = null;
    },
    AddFlavour() {
      this.CheckSImage();
      this.CheckName();
      this.checkPage();
      if (
        this.CheckSImage() == true &&
        this.CheckName() == true &&
        this.checkPage() == true
      ) {
        this.saving = true;
        if (this.myObj.id == 0) {
          var axios = require("axios");
          var config = {
            method: "post",
            url: "https://api.worldappeal.org.uk/api/Priorities",
            headers: {
              Authorization: "bearer " + this.$store.state.userData.token,
            },
            data: this.myObj,
          };

          axios(config)
            .then((response) => {
              this.saving = false;
              if (response.data.status === "success") {
                Swal.fire("Success!", "Priority added.", "success").then(
                  (res) => {
                    this.LoadData();
                    this.$bvModal.hide("modal-login");
                  }
                );
              } else {
                this.$bvToast.toast("Something went wrong.", {
                  title: "Error!",
                  variant: "danger",
                  toaster: "b-toaster-bottom-center",
                });
              }
            })
            .catch(function(error) {
              this.saving = false;
              console.log(error);
            });
        } else {
          var axios = require("axios");
          var config = {
            method: "put",
            url:
              "https://api.worldappeal.org.uk/api/Priorities/" + this.myObj.id,
            headers: {
              Authorization: "bearer " + this.$store.state.userData.token,
            },
            data: this.myObj,
          };

          axios(config)
            .then((response) => {
              this.saving = false;
              if (response.data.status === "success") {
                this.LoadData();
                this.$bvModal.hide("modal-login");
                this.$bvToast.toast("Priority updated successfully.", {
                  title: "Success!",
                  variant: "success",
                  toaster: "b-toaster-top-center",
                });
              } else {
                this.$bvToast.toast("Something went wrong.", {
                  title: "Error!",
                  variant: "danger",
                  toaster: "b-toaster-top-center",
                });
              }
            })
            .catch(function(error) {
              this.saving = false;
              console.log(error);
            });
        }
      }
    },
    LoadData() {
      this.dataLoading = true;
      var axios = require("axios");
      var config = {
        method: "get",
        url: "https://api.worldappeal.org.uk/api/Priorities",
        headers: {
          Authorization: "bearer " + this.$store.state.userData.token,
        },
      };

      axios(config)
        .then((response) => {
          // console.log(response);
          this.items = response.data.data;
        })
        .catch(function(error) {
          console.log(error);
        })
        .finally(() => (this.dataLoading = false));
    },
    LoadPages() {
      var axios = require("axios");
      var config = {
        method: "get",
        url: "https://api.worldappeal.org.uk/api/Page",
        headers: {
          Authorization: "bearer " + this.$store.state.userData.token,
        },
      };
      axios(config)
        .then((res) => {
          // console.log(res.data.data);
          this.pageOptions = res.data.data;
        })
        .catch(function(error) {
          console.log(error);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
<style scoped>
.image {
  float: left;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  border: 1px solid #ebebeb;
  margin: 5px;
}
.scrolling-wrapper {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  overflow-x: auto;
}
.scrolling-wrapper .moreImg {
  margin-right: 8px;
  position: relative;
}
.my-close {
  position: absolute;
  right: 0px;
  top: 0px;
  cursor: pointer;
}
</style>
